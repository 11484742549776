import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig, Tag } from 'antd'
import { Products } from 'gadjet-v2-types/dist/model'
import { CreditType, Pagination } from 'gadjet-v2-types/dist/type'
import { creditType } from 'gadjet-v2-types/dist/type/label'

import { krw } from '@utils/format'

import { BranchProductAction } from '@pages/Branch/ProductPage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  products: Products[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchProductAction, productId: number) => void
  onChangeActiveStatus: (status: boolean, rentalId: number) => void
}
export default function BranchProductListTable({
  loading,
  total,
  pagination,
  products,
  onChangePagination,
  onAction,
  onChangeActiveStatus,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Products>[] = [
    { key: 'name', dataIndex: 'name', title: '이름' },
    { key: 'description', dataIndex: 'description', title: '설명' },
    {
      key: 'price',
      dataIndex: 'price',
      title: '가격',
      align: 'right',
      render: (price) => `${krw(price, true)} 크레딧`,
    },
    { key: 'stock', dataIndex: 'stock', title: '재고', render: (stock) => `${stock}개` },
    { key: 'salesAmount', dataIndex: 'salesAmount', title: '판매량', render: (salesAmount) => `${salesAmount}개` },
    {
      key: 'availableCreditType',
      dataIndex: 'availableCreditType',
      title: '크레딧',
      render: (availableCreditType: CreditType[]) =>
        availableCreditType.map((type) => <Tag key={type}>{creditType[type]}</Tag>),
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: '활성 / 비활성',
      align: 'center',
      width: '150px',
      render: (isActive, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="true" onClick={() => onChangeActiveStatus(true, record.productId)}>
                활성
              </Menu.Item>
              <Menu.Item key="false" onClick={() => onChangeActiveStatus(false, record.productId)}>
                비활성
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button className={isActive ? 'green-btn' : 'red-btn'}>{isActive ? '활성' : '비활성'}</Button>
        </Dropdown>
      ),
    },
    {
      key: 'productId',
      dataIndex: 'productId',
      title: '',
      width: '80px',
      render: (productId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', productId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', productId)}>
                삭제
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table<Products>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ productId }) => `${productId}`}
        dataSource={products}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
